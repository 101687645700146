import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Box from "../../../../../components/box"
import CourseFooterNext from "../../../../../components/course-footer-next"
import FeedbackHeaderBool from "../../../../../components/feedback-header-bool"
import { getCourseNavigation } from "../../../../../store/courses"
import { useCourseStore } from "../../../../../store/courses"
import Paragraph from "../../../../../components/paragraph"

const Page = () => {
  const { dictAnswerIsValid } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })
  const task = {
    courseId: "haelfte-des-lebens",
    chapterId: "01-zwei-haelften",
    taskId: "strophen-und-verse",
  }
  const isValid =
    dictAnswerIsValid({ ...task, dictId: "strophen" }) &&
    dictAnswerIsValid({ ...task, dictId: "verse1" }) &&
    dictAnswerIsValid({ ...task, dictId: "verse2" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          title="Alles klar soweit?"
          label="Weiter"
          to="/kurse/haelfte-des-lebens/01-zwei-haelften/silben-klopfen"
        />
      }
    >
      <Seo title="Strophen und Verse" />
      <Box>
        <Stack>
          <FeedbackHeaderBool
            confirmed={isValid}
            title="Das Gedicht besteht aus 2 Strophen mit jeweils 7 Versen."
            level={4}
          />
          <Paragraph>
            Die beiden Strophen sind exakt symmetrisch aufgebaut: Jede besteht
            aus 7 Versen. Die dazwischenstehende Leerzeile fungiert somit als
            Symmetrie-Achse.
          </Paragraph>
          <Paragraph>
            Dazu passt, dass die 1. Strophe mit dem Wort »Wasser« endet: Alles
            in dieser 1. Strophe beugt und neigt sich dem Wasser entgegen und
            erinnert damit an den griechischen Mythos um den schönen Narziss:
            Als er sich über das Wasser beugt, verliebt er sich in sein eigenes
            Spiegelbild – eine Liebe, die ewig unerfüllt bleiben wird. In
            ›Hälfte des Lebens‹ setzt mit dem Aufprall auf dem Wasser zu Beginn
            der 2. Strophe ein Klageruf ein: »Weh mir«. Alles Schöne wird in
            sein Gegenteil verkehrt.
          </Paragraph>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
